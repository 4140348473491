import * as prismic from '@prismicio/client';

// -- Prismic Repo Name
// export const repoName = 'oliver';
export const repoName = process.env.prismicRepoName;

// -- Prismic API endpoint
export const endpoint = prismic.getEndpoint(repoName);

// -- Access Token if the repository is not public
export const accessToken = undefined;

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (document) => {
  switch (document.type) {
    case 'home':
      return '/';
    case 'project':
      return `/projects/${document.uid}`;
    case 'category':
      return `/projects/category/${document.uid}`;
    default:
      return '/';
  }
};

// -- Route Resolver rules
// Manages the url links to internal Prismic documents two levels deep (optionals)
export const routes = [
  {
    type: 'home',
    path: '/',
  },
  {
    type: 'project',
    path: '/projects/:uid',
  },
  {
    type: 'category',
    path: '/projects/category/:uid',
  },
];

// ? Currently using beta version: https://prismic.io/blog/new-core-development-kits-now-in-beta
export const getPrismicClient = (options?: prismic.ClientConfig) => (
  prismic.createClient(endpoint, {
    routes,
    ...options,
  })
);

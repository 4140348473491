import Link from 'next/link';
import Image from 'next/image';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { asText } from '@prismicio/helpers';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';
import { FC, Suspense } from 'react';
import type { Category } from '@/utils/prismic/typings';

import DisableSSR from '@/c/utils/DisableSSR';
import { linkResolver, getPrismicClient } from '@/utils/prismic/helpers';

import mediumIcon from '@/assets/medium-icon.svg';
import taipeiIcon from '@/assets/taipei-icon.svg';
import kaohsiungIcon from '@/assets/kaohsiung-icon.svg';

const MediumIcon: FC<{ size: number }> = ({ size }) => (
  <div className="flex items-center justify-center" style={{ width: size, height: size }}>
    <Image src={mediumIcon} width={size - 4} height={size - 4} alt="Medium" />
  </div>
);

const useAllCategories = () => useQuery(
  ['useAllCategories'],
  async () => {
    const client = getPrismicClient();

    return client.getAllByType<Category>('category', {
      orderings: ['my.category.order'],
    });
  },
  { suspense: true },
);

const useCategoriesWithoutAll = () => {
  const { data: categories } = useAllCategories();
  return categories?.filter((category) => category.uid !== 'all');
};

const Categories = () => {
  const categories = useCategoriesWithoutAll();

  return (
    categories?.length
      ? (
        <>
          {categories.map((category) => (
            <a
              key={category.id}
              href={linkResolver(category)}
            >
              {`${asText(category.data.name)}設計`}
            </a>
          ))}
        </>
      )
      : null
  );
};

const CategoriesSkeleton = () => (
  <>
    <a><Skeleton width="68%" /></a>
    <a><Skeleton width="68%" /></a>
    <a><Skeleton width="68%" /></a>
    <a><Skeleton width="68%" /></a>
    <a><Skeleton width="68%" /></a>
  </>
);

const CategoriesWithSuspense = () => (
  <DisableSSR>
    <Suspense fallback={<CategoriesSkeleton />}>
      <Categories />
    </Suspense>
  </DisableSSR>
);

const MobileFooter = () => (
  <div className="sm:hidden w-full tracking-wide mt-10">
    {/* Links */}
    <div className="w-full px-4 py-8 grid grid-cols-2 bg-gray-50">
      <div className="flex flex-col space-y-3 text-center">
        <div className="text-lg">奧立佛x竺居設計</div>
        <Link href="/projects/category/all">
          <a>設計作品</a>
        </Link>
        <Link href="/about">
          <a>公司介紹</a>
        </Link>
        <Link href="/sop">
          <a>服務流程</a>
        </Link>
        <Link href="/awards">
          <a>得獎紀錄</a>
        </Link>
        <Link href="/contact">
          <a>聯絡我們</a>
        </Link>
        <Link href="/inquiry">
          <a>免費評估</a>
        </Link>
        <Link href="https://medium.com/oliver-interior-design">
          <a>部落格</a>
        </Link>
      </div>
      <div className="flex flex-col space-y-3 text-center">
        <div className="text-lg">室內設計服務</div>
        <CategoriesWithSuspense />
      </div>
    </div>
    {/* Social Links */}
    <div className="w-full flex justify-center py-6 space-x-6 bg-gray-100">
      <a
        rel="noreferrer"
        href="https://www.facebook.com/KHinteriordesign"
        target="_blank"
      >
        <FacebookIcon sx={{ fontSize: 40 }} />
      </a>
      <a
        rel="noreferrer"
        href="https://www.instagram.com/oliverdesign_x_revieworks"
        target="_blank"
      >
        <InstagramIcon sx={{ fontSize: 40 }} />
      </a>
      <a
        rel="noreferrer"
        href="https://www.youtube.com/channel/UCUi-04bWpcF8lxdCB2dtk1Q/featured"
        target="_blank"
      >
        <YouTubeIcon sx={{ fontSize: 40 }} />
      </a>
      <a
        rel="noreferrer"
        href="https://medium.com/oliver-interior-design"
        target="_blank"
      >
        <MediumIcon size={40} />
      </a>
    </div>
    {/* Locations */}
    <div className="flex justify-center py-6 bg-gray-50">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row space-x-4 items-end">
          <div className="">
            <Image src={kaohsiungIcon} width={40} height={40} alt="Kaohsiung" />
          </div>
          <div className="">
            <div className="">高雄</div>
            <a className="block" href="tel:+88672229568">07-2229568</a>
            <a className="block" href="https://g.page/oliverinteriordesign">高雄市新興區中正三路31號2樓</a>
          </div>
        </div>
        <div className="flex flex-row space-x-4 items-end">
          <div className="">
            <Image src={taipeiIcon} width={40} height={40} alt="Taipei" />
          </div>
          <div>
            <div className="">台北</div>
            <a className="block" href="tel:+886227372727">02-27372727</a>
            <a className="block" href="https://goo.gl/maps/L7Ki4xwZqz2TU7p2A">台北市信義區嘉興街127巷4號1樓</a>
          </div>
        </div>
      </div>
    </div>
    {/* Copyright declaration */}
    <div className="w-full flex justify-center px-1 py-4 sm:py-8 font-thin text-xs sm:text-sm text-gray-500 tracking-tight bg-gray-100">
      Copyright &copy; 2021 Oliver Interior Design. All rights reserved.
    </div>
  </div>
);

const DesktopFooter = () => (
  <div className="hidden sm:block border-t border-b border-gray-500 py-12 px-14 tracking-wide">
    <div className="grid grid-cols-9 gap-2">
      <div className="col-span-2 flex flex-col space-y-3">
        <div className="text-lg">奧立佛x竺居設計</div>
        <Link href="/projects/category/all">
          <a>設計作品</a>
        </Link>
        <Link href="/about">
          <a>公司介紹</a>
        </Link>
        <Link href="/sop">
          <a>服務流程</a>
        </Link>
        <Link href="/awards">
          <a>得獎紀錄</a>
        </Link>
        <Link href="/contact">
          <a>聯絡我們</a>
        </Link>
        <Link href="/inquiry">
          <a>免費評估</a>
        </Link>
        <Link href="https://medium.com/oliver-interior-design">
          <a>部落格</a>
        </Link>
      </div>
      <div className="col-span-2 flex flex-col space-y-3">
        <div className="text-lg">室內設計服務</div>
        <CategoriesWithSuspense />
      </div>
      <div className="col-span-2 flex flex-col space-y-3">
        <div className="text-lg">社群媒體</div>
        <a
          rel="noreferrer"
          href="https://www.facebook.com/KHinteriordesign"
          target="_blank"
          className="flex items-center space-x-1"
        >
          <FacebookIcon />
          <div className="leading-none">Facebook</div>
        </a>
        <a
          rel="noreferrer"
          href="https://www.instagram.com/oliverdesign_x_revieworks"
          target="_blank"
          className="flex items-center space-x-1"
        >
          <InstagramIcon />
          <div className="leading-none">Instagram</div>
        </a>
        <a
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCUi-04bWpcF8lxdCB2dtk1Q/featured"
          target="_blank"
          className="flex items-center space-x-1"
        >
          <YouTubeIcon />
          <div className="leading-none">YouTube</div>
        </a>
        <a
          rel="noreferrer"
          href="https://medium.com/oliver-interior-design"
          target="_blank"
          className="flex items-center space-x-1"
        >
          <MediumIcon size={24} />
          <div className="leading-none">Medium</div>
        </a>
      </div>
      <div className="col-span-3 flex flex-col space-y-3">
        <div className="text-lg">聯絡資訊</div>
        <div className="">
          <div className="">高雄</div>
          <a className="block" href="tel:+88672229568">07-2229568</a>
          <a className="block" href="https://g.page/oliverinteriordesign">高雄市新興區中正三路31號2樓</a>
        </div>
        <div>
          <div className="">台北</div>
          <a className="block" href="tel:+886227372727">02-27372727</a>
          <a className="block" href="https://goo.gl/maps/L7Ki4xwZqz2TU7p2A">台北市信義區嘉興街127巷4號1樓</a>
        </div>
      </div>
    </div>
  </div>
);

const Footer = () => (
  <div className="sm:container mx-auto font-light">
    {/* Footer items for Mobile */}
    <MobileFooter />
    {/* Footer items for Desktop */}
    <DesktopFooter />
    {/* Copyright declaration (desktop) */}
    <div className="hidden sm:flex w-full justify-center px-1 py-4 sm:py-8 font-thin text-sm text-gray-500">
      Copyright &copy; 2021 Oliver Interior Design. All rights reserved.
    </div>
  </div>
);

export default Footer;

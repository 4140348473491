import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';

const Title: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Head>
    <title>
      {children
        // e.g., 所有作品 - 奧立佛 x 竺居聯合設計
        ? `${children} - ${process.env.websiteName}`
        // e.g., 奧立佛 x 竺居聯合設計
        : process.env.websiteName}
    </title>
  </Head>
);

export default Title;

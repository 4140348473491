import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useScroll, useWindowScroll } from 'react-use';
import {
  FC, PropsWithChildren, useEffect, useRef, useState,
} from 'react';

import logoPrimary from '@/assets/oliver-logo-primary.svg';

const DesktopMenuItem: FC<PropsWithChildren<{ href: string }>> = ({ children, href }) => {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <a
      href={href}
      className={clsx(
        'block relative py-2.5 text-lg',
        'hover:font-normal transition-all duration-300',
        isActive ? 'font-normal' : 'font-light',
      )}
    >
      {children}
      {/* The dot at bottom of menu item when the item is active */}
      {isActive && (
        <div
          className={clsx(
            'absolute bottom-1.5 left-[50%] translate-x-[-50%]',
            'w-[2px] h-[2px] border-[2px] rounded-full border-black',
          )}
        />
      )}
    </a>
  );
};

const MobileMenuItem: FC<PropsWithChildren<{ href: string }>> = ({ children, href }) => {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <div
      className={clsx(
        'h-full flex justify-center items-center font-light flex-shrink-0 leading-none',
        isActive && '!font-medium',
      )}
    >
      <a href={href}>{children}</a>
    </div>
  );
};

const DesktopMenuItems = () => {
  // Tracking menu container
  const menuContainer = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);
  const [menuTopPosition, setMenuTopPosition] = useState(0);
  useEffect(() => setMenuWidth(menuContainer?.current?.offsetWidth), [menuContainer]);
  useEffect(() => setMenuHeight(menuContainer?.current?.offsetHeight), [menuContainer]);
  useEffect(() => setMenuTopPosition(menuContainer?.current?.offsetTop), [menuContainer]);

  // Fixing menu on top when scrolling passed the menu container
  const { y: currentYPosition } = useWindowScroll();
  const [fixedOnTop, setFixedOnTop] = useState(false);
  useEffect(() => {
    if (currentYPosition >= menuTopPosition) {
      setFixedOnTop(true);
    } else {
      setFixedOnTop(false);
    }
  }, [currentYPosition, menuTopPosition]);

  // Set menu width when fixed on top
  useEffect(() => {
    if (!fixedOnTop) return;
    menuContainer.current.style.width = `${menuWidth}px`;
  }, [fixedOnTop, menuWidth]);

  return (
    <>
      <div
        ref={menuContainer}
        className={clsx(
          'hidden sm:flex space-x-9 justify-center',
          'border-t border-b border-black text-black bg-white',
          fixedOnTop && 'fixed top-0 z-50',
        )}
      >
        <DesktopMenuItem href="/">首頁</DesktopMenuItem>
        <DesktopMenuItem href="/projects/category/all">設計作品</DesktopMenuItem>
        <DesktopMenuItem href="/about">公司介紹</DesktopMenuItem>
        <DesktopMenuItem href="/sop">服務流程</DesktopMenuItem>
        <DesktopMenuItem href="/awards">得獎紀錄</DesktopMenuItem>
        <DesktopMenuItem href="https://medium.com/oliver-interior-design">部落格</DesktopMenuItem>
        <DesktopMenuItem href="/contact">聯絡我們</DesktopMenuItem>
      </div>
      {/* Placeholder */}
      {!!fixedOnTop && (
        <div className="hidden sm:block w-full" style={{ height: `${menuHeight}px` }} />
      )}
    </>
  );
};

const MobileMenuItems = () => {
  // Control whether to hide the rest of the menu items
  const scrollableContainer = useRef<HTMLDivElement>(null);
  const { x: currentXPosition } = useScroll(scrollableContainer);
  const [hideRestItems, setHideRestItems] = useState(true);
  useEffect(() => {
    const { current } = scrollableContainer;

    const visibleWidth = current.offsetWidth;
    const contentFullWidth = current.scrollWidth;
    const remainingWidth = contentFullWidth - visibleWidth - currentXPosition;

    if (remainingWidth < 46) {
      setHideRestItems(false);
    } else {
      setHideRestItems(true);
    }
  }, [currentXPosition]);

  // Tracking menu container
  const menuContainer = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const [menuTopPosition, setMenuTopPosition] = useState(0);
  useEffect(() => setMenuHeight(menuContainer?.current?.offsetHeight), [menuContainer]);
  useEffect(() => setMenuTopPosition(menuContainer?.current?.offsetTop), [menuContainer]);

  // Fixing menu on top when scrolling passed the menu container
  const { y: currentYPosition } = useWindowScroll();
  const [fixedOnTop, setFixedOnTop] = useState(false);
  useEffect(() => {
    if (currentYPosition >= menuTopPosition) {
      setFixedOnTop(true);
    } else {
      setFixedOnTop(false);
    }
  }, [currentYPosition, menuTopPosition]);

  return (
    <>
      <div
        ref={menuContainer}
        className={clsx(
          'sm:hidden w-full z-50 box-border',
          'border-t border-b border-black text-black bg-white',
          fixedOnTop && 'fixed top-0 left-0 right-0',
        )}
      >
        <div className="relative">
          <div className="h-[45px] relative">
            <div className="w-full h-full relative overflow-hidden">
              <div className="w-full h-full overflow-x-scroll scrollbar-hide" ref={scrollableContainer}>
                {/* Spring animation to highlight the menu is scrollable */}
                <motion.div
                  className="h-full w-full flex space-x-6 px-6 items-center"
                  style={{ marginLeft: 100 }}
                  animate={{ marginLeft: 0 }}
                  transition={{
                    delay: 0.168,
                    type: 'spring',
                    bounce: 0.66,
                    damping: 8,
                    duration: 1.288,
                  }}
                >
                  <MobileMenuItem href="/">首頁</MobileMenuItem>
                  <MobileMenuItem href="/projects/category/all">設計作品</MobileMenuItem>
                  <MobileMenuItem href="/about">公司介紹</MobileMenuItem>
                  <MobileMenuItem href="/sop">服務流程</MobileMenuItem>
                  <MobileMenuItem href="/awards">得獎紀錄</MobileMenuItem>
                  <MobileMenuItem href="https://medium.com/oliver-interior-design">部落格</MobileMenuItem>
                  <MobileMenuItem href="/contact">聯絡我們</MobileMenuItem>
                  <div className="flex-shrink-0 w-1 h-1" />
                </motion.div>
              </div>
              {/* Hide rest items with gradient background */}
              <div
                className={clsx(
                  'absolute top-0 right-0 bottom-0 h-full w-[46px]',
                  'bg-gradient-to-l from-white',
                  'transition-all duration-500',
                  !hideRestItems ? 'right-[-46px]' : 'right-0',
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Placeholder */}
      {!!fixedOnTop && (
        <div className="sm:hidden w-full" style={{ height: `${menuHeight}px` }} />
      )}
    </>
  );
};

const Logo = () => (
  <Link href="/">
    <a className="block max-w-[500px] mx-auto p-4 sm:p-0 sm:py-8">
      <Image
        src={logoPrimary}
        alt="Oliver Interior Design logo"
        layout="responsive"
      />
    </a>
  </Link>
);

const NavBar = () => (
  <div className="sm:container mx-auto">
    <Logo />
    <DesktopMenuItems />
    <MobileMenuItems />
  </div>
);

export default NavBar;
